import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import { observer } from 'mobx-react'

import Csc_Top from './com/csc_top.js';
import Csc_Mnu from './com/csc_mnu.js';
import Csc_Foot from './com/csc_foot.js';

import Csc_Dash from './dash.js';
import Csc_Oms from './oms.js';
import Csc_Bi from './bi.js';
import Csc_Stat from './sts.js';
import Csc_Rps from './rps.js';
import Csc_Units from './units.js';
import Csc_UC from './uc.js';

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sso from "../../sso";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Csc extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, mnu_show: true, deps: [] };
    this.state.uc = 0;
    sso.sdo.csa = [];
    this.set_csa();
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    var deps = [];
    deps.push( {"id": "00", "text": "" } );

    //this.props.store.seo.cs = "5";
    ///this.props.store.seo.unit = "BSENHET";

    var pos = {};
    pos.deps = deps;
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
    //this.units();
  }

    set_csa() {
        var csa = [];

        if(false) {
            csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { c0lopnr:"1", c0sid:"S0003101", c0text: "TGIF KUNGSTRADGARDEN", c0typ: "", unit: "tgif01"},
                { c0lopnr:"2", c0sid:"S0003102", c0text: "TGIF TABY", c0typ: "", unit: "tgif02"},
                { c0lopnr:"3", c0sid:"S0003103", c0text: "TGIF MALMO", c0typ: "", unit: "tgif03"},
                { c0lopnr:"4", c0sid:"S0003104", c0text: "TGIF GAMLASTAN", c0typ: "", unit: "tgif04"},
                { c0lopnr:"5", c0sid:"S0003105", c0text: "TGIF UPPSALA", c0typ: "", unit: "tgif05"}
            ];
            //this.setState({ csa: csa });
            sso.sdo.csa = csa;
        }
        if(sso.seo.csid == "1000") {
            csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { c0lopnr:"1", c0sid:"S0003201", c0text: "SYSTEM 1", c0typ: ""},
                { c0lopnr:"3", c0sid:"S0003202", c0text: "SYSTEM 2", c0typ: ""},
                //{ c0lopnr:"5", c0sid:"S0003203", c0text: "SYSTEM 3", c0typ: ""}
            ];
            //this.setState({ csa: csa });
            sso.sdo.csa = csa;
        }
        if(sso.seo.csid == "0002") {
            csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { c0lopnr:"1", c0sid:"S0003101", c0text: "TGIF KUNGSAN", c0typ: "", unit: "tgif01"},
                { c0lopnr:"2", c0sid:"S0003102", c0text: "TGIF TABY", c0typ: "", unit: "tgif02"},
                { c0lopnr:"3", c0sid:"S0003103", c0text: "TGIF MALMO", c0typ: "", unit: "tgif03"},
                { c0lopnr:"4", c0sid:"S0003104", c0text: "TGIF GAMLASTAN", c0typ: "", unit: "tgif04"},
                { c0lopnr:"5", c0sid:"S0003105", c0text: "TGIF UPPSALA", c0typ: "", unit: "tgif05"}
            ];
            //this.setState({ csa: csa });
            sso.sdo.csa = csa;
        }
        if(sso.seo.csid == "0003") {
            csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { c0lopnr:"1", c0sid:"S0003101", c0text: "TGIF KUNGSAN", c0typ: "", unit: "tgif01"},
                { c0lopnr:"2", c0sid:"S0003102", c0text: "TGIF TABY", c0typ: "", unit: "tgif02"},
                { c0lopnr:"3", c0sid:"S0003103", c0text: "TGIF MALMO", c0typ: "", unit: "tgif03"},
                { c0lopnr:"4", c0sid:"S0003104", c0text: "TGIF GAMLASTAN", c0typ: "", unit: "tgif04"},
                { c0lopnr:"5", c0sid:"S0003105", c0text: "TGIF UPPSALA", c0typ: "", unit: "tgif05"}
            ];
            //this.setState({ csa: csa });
            sso.sdo.csa = csa;
        }
        if(sso.seo.csid == "0011") {
            csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { c0lopnr:"1", c0sid:"S0002046", c0text: "GLASSMAGASINET", c0typ: "", unit: "glassm00"},
                { c0lopnr:"2", c0sid:"S0002043", c0text: "HÄSTBACKEN", c0typ: "", unit: "glassm03"},
                { c0lopnr:"3", c0sid:"S0002044", c0text: "STORA TORGET", c0typ: "", unit: "glassm04"},
            ];
            //this.setState({ csa: csa });
            sso.sdo.csa = csa;
        }
        if(sso.seo.csid == "0021") {
            csa = [
                //{ id:"0", typ:"0", text: "INGET CENTRALSYSTEM"},
                { c0lopnr:"1", c0sid:"S0004101", c0text: "LIONBAR VISBY", c0typ: "", unit: "lion0001"},
                { c0lopnr:"2", c0sid:"S0004102", c0text: "LIONBAR VASASTAN", c0typ: "", unit: "lion0002"},
                { c0lopnr:"3", c0sid:"S0004103", c0text: "LIONBAR MARIATORGET", c0typ: "", unit: "lion0003"},
                { c0lopnr:"4", c0sid:"S0004201", c0text: "GERMAN VISBY", c0typ: "", unit: "german01"},
            ];
            //this.setState({ csa: csa });
            sso.sdo.csa = csa;
        }
        //alert(JSON.stringify(sso.seo));
        //alert(JSON.stringify(sso.sdo));
    }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    pos_init() {
    var self = this;

	var req = {};
	req.req = "pos.pos_fkn.pos_init";

    //gda.wndLoading("Hämtar");
    net.sio_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //var pos = {};
        //pos.deps = ret.rco.deps;
        //self.props.store.pos = pos;
        if(!self.props.store.pos) self.props.store.pos = {};
        self.props.store.pos.deps = ret.rco.deps;
        self.props.store.pos.vgrps = ret.rco.vgrps;

        //self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    })
    }
  units() {
    var self = this;

    if(!self.props.store.pos) self.props.store.pos = {};
    self.props.store.pos.csa = [];

    //this.props.store.seo.cs = "0012";
    if( !this.props.store.seo.cs || ( this.props.store.seo.cs.length < 1)) return;

    var prm = {};
    prm.req = "cs.cs_units.csa";
	prm.cs = this.props.store.seo.cs;
	prm.csid = this.props.store.seo.csid;

    net.ccs_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert(JSON.stringify(ret));
            return;
        }
        var sa = ret.rca;
        if(!self.props.store.pos) self.props.store.pos = {};
        self.props.store.pos.csa = sa;
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

  render() {

      if(this.state.uc == 1) {
          return(
              <div className="web-app">
                  <div className="web-top">
                      <Csc_Top store={this.store} />
                  </div>
                  <div className="web-main">
          <div className="web-body">
              <Routes>
                  <Route path="*" element={ <Csc_UC store={this.store} />} />
              </Routes>
          </div>
                  </div>
              </div>
          );
      }

    return (
    <div className="web-app">
    <div className="web-top">
        <Csc_Top store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    { this.props.store.cfg.mnu_show ?
    <div className="web-side">
        <Csc_Mnu store={this.store} />

    </div>
    : null }

    <div className="web-body">
        <Routes>
            <Route path="oms/*" element={ <Csc_Oms store={this.store} />} />
            <Route path="bi/*" element={ <Csc_Bi store={this.store} />} />
            <Route path="stat/*" element={ <Csc_Stat store={this.store} />} />
            <Route path="rps" element={ <Csc_Rps store={this.store} />} />
            <Route path="units" element={ <Csc_Units store={this.store} />} />
            <Route path="*" element={ <Csc_Dash store={this.store} />} />
        </Routes>
    </div>

    </div>

    </div>

        <Csc_Foot store={this.store} />

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Csc));
