import React, { useState, useEffect, useContext } from 'react';

import * as sf from "../../lib/strfkn";

import * as net from "../../lib/net";
import Tid_FT from "../../lib/ui/tid_ft";
import Edit_Txt from "../../lib/ui/edit_txt";
import Fr_Input from "../../lib/ui/fr_input";
import ddo from "../../ddo";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Pos_Happy extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;

    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = ddo.cache.avd;
    this.state.hao = {};
    this.state.haa = [];
    this.state.aa = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.happy();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      //css.height = 100;
      css.overflow = "auto";
      return css;
  }
  onUnitChange(e) {
        this.setState({unit: e.value});
  }

  plu_open(row) {
      var self = this;
  }
  plu_edit(row) {
      var self = this;
      //alert(JSON.stringify(row.plu00));
  }

  happy() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_prm.prm_happy";
    prm.avd = ddo.cache.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));

        var a = [];
        var keys = Object.keys(ret.rco);
        for(var key of keys) {
            var o = ret.rco[key];
            a.push( o );
        }

        self.setState({hao: ret.rco });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
  happy_save() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_prm.prm_happy_upd";
    prm.avd = ddo.cache.avd;
    prm.happy = this.state.hao;

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rco));
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    happynr_save (row, ix, value) {
        //alert(JSON.stringify(row));
        //alert(JSON.stringify(value));
      var hao = this.state.hao;
      //var na = name.split(".");
      //pbo[na[0]][na[1]] = value;
      row[ix] = value;
      this.setState({hao: hao});
    }

  render() {
    return (
    <div className="web-app">

    <div className="flex-row">
        <button className="btn btn-hw-green w150" onClick={() => this.happy_save() } aria-label="System" >
            SPARA
        </button>
        <div className="flex"></div>
        <button className="btn btn-hw-blue w150" onClick={() => this.happy() } aria-label="System" >
            Visa
        </button>
    </div>
    <div className="divider_green"></div>

    <div className="cdi-table">
        <div className="flex-row cdi-head">
            <div size="4" md="2" className="flex-col text-left">Text</div>
            <div size="8" md="10" className="flex-col">
            <div className="flex-row">
            <div className="cdi-col" size="6" md="3">Happy 1</div>
            <div className="cdi-col" size="6" md="3">Happy 2</div>
            <div className="cdi-col" size="6" md="3">Happy 3</div>
            <div className="cdi-col" size="6" md="3">Happy 4</div>
            </div>
            </div>
        </div>
        <div style={ this.tb_style() }>
        { Object.values(this.state.hao).map((row, key) =>
        <div key={key} className="cdi-row" onClick={() => this.plu_edit(row) }>
            <div size="4" md="2" className="">{ row.wday }</div>
            <div size="8" md="10" className="flex-col">
                 <div className="flex-row">
                <div className="flex-row"  size="6" md="3">
                    <Tid_FT from={row.f01} to={row.t01} fromval={(e) => row.f01 = e} toval={(e) => row.t01 = e } />
                    <Edit_Txt clsName="lh_35" text={ row.n01 } getValue={(e) => this.happynr_save(row, "n01", e) } />
                </div>
                <div className="flex-row"  size="6" md="3">
                    <Tid_FT from={row.f02} to={row.t02} fromval={(e) => row.f02 = e} toval={(e) => row.t02 = e } />
                    <Edit_Txt clsName="lh_35" text={ row.n02 } getValue={(e) => this.happynr_save(row, "n02", e) } />
                </div>
                <div className="flex-row"  size="6" md="3">
                    <Tid_FT from={row.f03} to={row.t03} fromval={(e) => row.f03 = e} toval={(e) => row.t03 = e } />
                    <Edit_Txt clsName="lh_35" text={ row.n03 } getValue={(e) => this.happynr_save(row, "n03", e) } />
                </div>
                <div className="flex-row"  size="6" md="3">
                    <Tid_FT from={row.f04} to={row.t04} fromval={(e) => row.f04 = e} toval={(e) => row.t04 = e } />
                    <Edit_Txt clsName="lh_35" text={ row.n04 } getValue={(e) => this.happynr_save(row, "n04", e) } />
                </div>
                 </div>
            </div>
        </div>
        )}
        </div>
    </div>

    </div>
  );
    }
}
export default Pos_Happy;
